<template>

<app-panel v-on:close="$emit('close')" :loading="is.loading">
		
	<app-panel-title text="Choose variant" />

	<div class="assign-box-head">

		<div class="assign-box-head-image" v-bgimage="addon.item.image" />

		<div class="assign-box-head-text">
			<div class="assign-box-head-text-name">{{ addon.item.name }}</div>
		</div>
				
	</div>

	<app-panel-check :disabled="is.saving" v-for="variant in variants" :key="variant.id" :caret="true" :text="variant.subtext" :active="selected === variant.id" v-on:toggle="selected = variant.id" />

	<app-panel-content :space="true">

		<app-button text="Confirm" :disabled="!selected" :loading="is.saving" v-on:click="onConfirmClick" />

	</app-panel-content>

</app-panel>

</template>

<script>

export default {

	props: ['addon'],

	data: function() {

		return {
			is: {
				saving: false,
				loading: true,
				error: false
			},
			selected: false,
			variants: []
		}

	},

	created: function() {

		this.$pubsub.$emit('offset', true)

		this.$api.post(['suggest', 'addon', 'variants'], {
			item: this.addon.item.id
		}).then(function(json) {
						
			this.variants = json.suggestions

			this.is.loading = false

		}.bind(this))
		
	},

	beforeDestroy: function() {

		this.$pubsub.$emit('offset', false)

	},

	methods: {

		onConfirmClick: function() {

			this.is.saving = true
			
			this.$api.post(['convention', 'registration', 'badge'], {
				addon: this.addon.id,
				variant: this.selected
			}).then(function() {

				this.$notify({
					message: 'Successfully updated.'
				})
				
				this.is.saving = false

				this.$emit('completed')

			}.bind(this), function() {

				this.$notify({
					message: 'Could not select variant.',
					type: 'error'
				})

				this.is.saving = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.assign-box-head {
	padding: 10px 20px 20px 20px;
	display: flex;
}

.assign-box-head-image {
	width: 40px;
	height: 40px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	margin-right: 10px;
}

.assign-box-head-text {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
}

.assign-box-head-text-name {
	font-size: 16px;
	color: #fff;
	font-weight: 700;
}

.assign-box-head-text-variant {
	font-size: 14px;
	color: #fff;
	font-weight: 400;
	margin-top: 4px;
}

.assign-box-content-text {
	color: #bfc4cb;
	font-weight: 300;
	font-size: 14px;
	line-height: 20px;
	padding: 0px 20px;
	margin-bottom: 20px;
}

.assign-box-content-item {
	font-size: 14px;
	color: #fff;
	font-weight: 400;
	padding: 10px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.assign-box-content-item-name {
	flex-grow: 1;
	flex-basis: 0;
}

.assign-box-content-item-count {
	margin-left: 10px;
	margin-right: 10px;
}

.assign-box-content-item-action {
	margin-left: 10px;
}

.assign-box-content-item:hover {
	background-color: #387DD8;
	color: #fff;
}

.assign-box-content-item >>> .icon {
	font-size: 16px;
	color: #ccc;
}

.assign-box-content-item:hover >>> .icon {
	color: rgba(255, 255, 255, 0.25);
}

.assign-box-content-item.is-active >>> .icon {
	font-size: 16px;
	color: #ffffff;
}

.assign-box-content-item.is-active:hover >>> .icon {
	color: #fff;
}

</style>
