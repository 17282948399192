<template>

<app-content-box title="Add-ons" :blank="true" icon="addons" :is-grid="true">

	<app-card v-on:click="onClick(addon)" :is-clickable="addon.variant.wildcard" :tooltip="(addon.variant.wildcard) ? 'Click to choose variant' : false" class="addons-item" :alert="addon.variant.wildcard" :brand="(addon.variant.wildcard) ? 'Choose variant' : false" brandTheme="red" :badge="(addon.received) ? 'tickOnly' : false" :title="title(addon)" :subtitle="subtitle(addon)" :not-linked="true" :fit-image="true" :long-text="true" :image="addon.item.image" v-for="addon in addons" :key="addon.id" />

	<com-wildcard v-if="is.choosing" :addon="wildcard.addon" v-on:close="is.choosing = false" v-on:completed="onWildcardCompleted" />

</app-content-box>

</template>

<script>

import comWildcard from './Wildcard'

export default {

	props: ['addons'],

	components: {
		'com-wildcard': comWildcard
	},

	data: function() {

		return {
			is: {
				choosing: false
			},
			wildcard: {
				addon: false
			}
		}

	},

	methods: {

		onWildcardCompleted: function() {

			this.is.choosing = false

			this.$emit('refresh')

		},

		onClick: function(addon) {

			if (addon.variant.wildcard) {

				this.wildcard.addon = addon

				this.is.choosing = true

			}

		},

		title: function(addon) {

			return (addon.variant.wildcard) ? addon.item.name : addon.name

		},

		subtitle: function(addon) {

			if (addon.received) {

				return 'Received at ' + this.$options.filters.localDate(addon.received, 'h:mma') + ' on ' + this.$options.filters.localDate(addon.received, 'dddd')
				
			} else if (addon.sale) {

				var subtitle = []

				if (addon.sale.type === this.$constants.registration.sale.type.comp) {

					subtitle.push('Received on')

				} else {
					
					subtitle.push('Purchased on')

				}

				subtitle.push(this.$options.filters.localDate(addon.sale.date.made, 'MM/DD/YYYY'))

				if (addon.sale.user.id !== addon.user.id) {

					subtitle.push('by ' + addon.sale.user.name)

				}

				return subtitle.join(' ')

			} else {

				return false

			}

		}

	}

}

</script>

<style scoped>

</style>
